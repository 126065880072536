<mat-dialog-content class="paddingZero" *blockUI="'notes-dialog'">
  <div class="modalHeader">
    <div class="titleContainer">
      <div class="mainTitle">Discussion notes</div>
    </div>
    <button mat-icon-button class="closeButton" (click)="onCloseClicked()" tabindex="-1">
      <mat-icon aria-hidden="false" fontIcon="close"></mat-icon>
    </button>
  </div>
  <div class="notes-container">
    <div class="note-section">
      <div class="note-input-section">
        @if(noteUnderEdit().note) {
        <div class="note-input-label">Editing note created at {{noteUnderEdit().note?.created | date:
          'dd/MM/yyyy hh:mm:ss a'}}...</div>
        } @else {
        <div class="note-input-label">New note</div>
        }
        <kendo-editor tabindex="0" class="note-input" [(value)]="noteUnderEdit().text" placeholder="Add note...">
          <kendo-toolbar>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
          </kendo-toolbar>
        </kendo-editor>
      </div>
      <div class="note-input-buttons">
        @if(noteUnderEdit().note) {
        <button tabindex="1" mat-button class="mainButton" (click)="onUpdateNoteClicked()">Update
          note</button>
        <button mat-button class="altButton" (click)="onClearNoteUnderEditClicked()">Cancel edit</button>
        } @else {
        <button tabindex="1" mat-button class="mainButton" (click)="onCreateNoteClicked(notesList)">Add note</button>
        }
      </div>
    </div>
    <div class="list-section">
      <div class="notes-list-header">Historic notes</div>
      <div class="notes-list" #notesList>
        @for (note of sortedNotes(); track note) {
        <div class="note-card">
          <div class="note-card-top">
            <div class="note-card-edit-section">
              <button mat-icon-button aria-label="Edit" (click)="onEditNoteClicked(note)"
                [disabled]="!note.isNoteEditable" matTooltip="Edit note">
                <mat-icon>edit</mat-icon>
              </button>
            </div>
            <div class="note-card-text" [innerHtml]="note.text"> </div>
          </div>
          <div>
            Added by <strong>{{note.createdByName}}</strong> on <strong>{{note.created | date:
              'dd/MM/yyyy'}}</strong> at
            <strong>{{note.created |
              date : "hh:mm:ss a"}}</strong>
          </div>

          @if(note.created.getTime() !== note.lastModified.getTime()) {
          <div>
            Last modified by <strong>{{note.lastModifiedByName}}</strong> on
            <strong>{{note.lastModified | date:
              'dd/MM/yyyy'}}</strong> at
            <strong>{{note.lastModified | date : "hh:mm:ss a"}}</strong>
          </div>
          }
        </div>
        }
      </div>
    </div>
  </div>
</mat-dialog-content>