import { NoteSection, PdfSection } from '../entities/pdf-content';
import { Observable, firstValueFrom, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationService } from './navigation.service';
import { Note } from '../entities/note';
import { PdfSectionTypes } from '../enums/pdf-section-type';
import moment from 'moment';
import { replaceTagsInNote } from '../shared/util';

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  constructor(
    private http: HttpClient,
    private navigationService: NavigationService
  ) { }

  private readonly apiPath = "/api/notes"

  getNotesForPageOfJourney(journeyID: string, pageID: number): Observable<Note[]> {
    return this.http.get<Note[]>(`${this.apiPath}/${journeyID}/${pageID}`)
      .pipe(map(notes => {
        for (const note of notes) {
          NotesService.convertStringsToDatesInNoteObject(note);
        }
        return notes;
      }));
  }

  getNotesForJourney(journeyID: string) {
    return this.http.get<Note[]>(`${this.apiPath}/getNotesForJourney/${journeyID}`)
      .pipe(map(notes => {
        for (const note of notes) {
          NotesService.convertStringsToDatesInNoteObject(note);
        }
        return notes;
      }));
  }

  createNote(note: Note): Promise<Note> {
    return firstValueFrom(this.http.post<Note>(this.apiPath, note)
      .pipe(map(x => NotesService.convertStringsToDatesInNoteObject(x))));
  }

  updateNote(note: Note): Promise<Note> {
    return firstValueFrom(this.http.put<Note>(this.apiPath, note)
      .pipe(map(x => NotesService.convertStringsToDatesInNoteObject(x))));
  }

  async getPdfSections(journeyID: string): Promise<PdfSection[]> {
    const pdfSections: PdfSection[] = [];

    const notes = await firstValueFrom(this.getNotesForJourney(journeyID));
    for (const step of this.navigationService.steps) {
      if (notes.some(x => x.pageID === String(step.page))) {
        pdfSections.push(NotesService.getNotePdfSection(notes.filter(x => x.pageID === String(step.page)), step.titleForPdfReport ?? step.title));
      }
    }

    return pdfSections;
  }

  private static getNotePdfSection(notes: Note[], pageName: string): PdfSection {
    const pdfSection: PdfSection = {
      breakLine: false,
      pdfSectionType: PdfSectionTypes.Note,
      title: pageName,
      content: []
    };

    for (const item of notes) {
      (pdfSection.content as NoteSection[]).push({
        info: NotesService.getNoteInfo(item),
        noteContent: replaceTagsInNote(item.text)
      });
    }

    return pdfSection;
  }

  private static getNoteInfo(note: Note): string {
    const isSame = moment(note.created).isSame(note.lastModified);
    return `Created ${moment(note.created).format("DD/MM/yyyy HH:mm:ss")} by ${note.createdByName}. ${(isSame ? "" : `Modified ${moment(note.lastModified).format("DD/MM/yyyy HH:mm:ss")} by ${note.lastModifiedByName}.`)}`;
  }

  private static convertStringsToDatesInNoteObject(note: Note): Note {
    note.created = new Date(note.created);
    note.lastModified = new Date(note.lastModified)
    return note;
  }
}
