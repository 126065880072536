import { BlockUI, BlockUIModule, NgBlockUI } from 'ng-block-ui';
import { ChangeDetectionStrategy, Component, Inject, OnInit, computed, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { catchError, finalize, firstValueFrom, forkJoin, map, tap } from 'rxjs';
import { AdviserService } from '../../services/adviser.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { DatePipe } from '@angular/common';
import { EditorModule } from "@progress/kendo-angular-editor";
import { FormsModule } from '@angular/forms';
import { JourneyService } from '../../services/journey.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { Note } from '../../entities/note';
import { NotesService } from '../../services/notes-service';
import { createGuid } from '../util';

@Component({
  selector: 'notes-viewer',
  standalone: true,
  imports: [MatDialogModule, MatIconModule, FormsModule, DatePipe, BlockUIModule, EditorModule, MatButtonModule, MatTooltip, MatSnackBarModule],
  templateUrl: './notes-viewer.component.html',
  styleUrl: './notes-viewer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotesViewerComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<NotesViewerComponent>,
    private notesService: NotesService,
    private journeyService: JourneyService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private adviserService: AdviserService,
    @Inject(MAT_DIALOG_DATA) data: DialogData

  ) {
    this.pageId = data.pageId;
  }

  @BlockUI('notes-dialog') blockUI!: NgBlockUI;
  noteUnderEdit = signal<NoteUnderEdit>({ text: "" });
  notes = signal<Note[]>([]);
  sortedNotes = computed(() => this.notes().sort((a, b) => b.created.getTime() - a.created.getTime()));

  private readonly snackbarDurationMs = 3000;
  private readonly pageId: number;
  // Should be enough. There is a 400kb document size limit in DynamoDB, so we need to limit it to under 400kb anyway.
  private readonly maxNoteCharacters = 50000;

  ngOnInit() {
    this.showLoadingIndicator();

    forkJoin(
      [
        this.adviserService.loadLoggedInUser(),
        this.notesService.getNotesForPageOfJourney(this.journeyService.getNonNullableJourney().journeyID, this.pageId)
      ])
      .pipe(
        tap(([, notes]) => { this.notes.set(notes) }),
        catchError((err) => {
          this.dialogRef.close();
          throw err;
        }),
        finalize(() => {
          this.hideLoadingIndicator();
        }))
      .subscribe();

    this.dialogRef.beforeClosed()
      .pipe(map(() => this.blockUiWrapper(() => this.promptToSave())))
      .subscribe();
  }

  onCloseClicked(): void {
    this.dialogRef.close();
  }

  async onEditNoteClicked(note: Note) {
    if (note.noteID === this.noteUnderEdit().note?.noteID) {
      // If the user clicks to edit the note they are currently editing, do nothing.
      return;
    }
    await this.blockUiWrapper(() => this.promptToSave());
    this.setNoteUnderEdit(note);
  }

  async onCreateNoteClicked(notesList: HTMLElement | null | undefined) {
    await this.blockUiWrapper(() => this.createNote())
    if (notesList) {
      notesList.scroll({ top: 0 })
    }
  }

  async onUpdateNoteClicked() {
    await this.blockUiWrapper(() => this.updateNote());
  }

  onClearNoteUnderEditClicked() {
    this.setNoteUnderEdit();
  }

  private async createNote() {
    this.snackBar.dismiss();

    if (!this.validateNote()) {
      return;
    }

    const dateNow = new Date();
    const note: Note = {
      text: this.noteUnderEdit().text,
      created: dateNow,
      createdByNode: this.journeyService.getNonNullableJourney().userNode,
      createdByName: this.adviserService.getLoggedInUserName(),
      lastModified: dateNow,
      lastModifiedByNode: this.journeyService.getNonNullableJourney().userNode,
      lastModifiedByName: this.adviserService.getLoggedInUserName(),
      journeyID: this.journeyService.getNonNullableJourney().journeyID,
      pageID: this.pageId.toString(),
      noteID: createGuid(true),
      subject: null
    };

    this.updateNoteInUi(await this.notesService.createNote(note));

    this.setNoteUnderEdit();
    this.snackBar.open("Note saved...", "Close", { duration: this.snackbarDurationMs });
  }

  private async updateNote() {
    this.snackBar.dismiss();

    if (!this.validateNote()) {
      return;
    }

    const currentNote = this.noteUnderEdit().note;

    if (!currentNote) {
      throw Error("You are trying to update a note that does not exist.");
    }

    if (!currentNote.isNoteEditable) {
      return;
    }

    const newNote: Note = {
      noteID: currentNote.noteID,
      journeyID: currentNote.journeyID,
      pageID: currentNote.pageID,
      created: currentNote.created,
      createdByNode: currentNote.createdByNode,
      createdByName: currentNote.createdByName,
      subject: currentNote.subject,

      text: this.noteUnderEdit().text,
      lastModified: new Date(),
      lastModifiedByNode: this.journeyService.getNonNullableJourney().userNode,
      lastModifiedByName: this.adviserService.getLoggedInUserName()
    }

    this.updateNoteInUi(await this.notesService.updateNote(newNote));
    this.setNoteUnderEdit();

    this.snackBar.open("Note updated...", "Close", { duration: this.snackbarDurationMs });
  }

  private validateNote(): boolean {
    const textWithoutHtmlTags = this.noteUnderEdit().text
      .replaceAll(new RegExp('<p>|</p>', "iug"), "").trim();

    if (textWithoutHtmlTags === "") {
      this.showErrorSnackbar("A note must contain text.");
      return false;
    }

    if (this.noteUnderEdit().text.length > this.maxNoteCharacters) {
      this.showErrorSnackbar(`A note cannot contain more than ${this.maxNoteCharacters} characters`);
    }

    return true;
  }

  private showLoadingIndicator() {
    this.blockUI.start();
  }

  private hideLoadingIndicator() {
    this.blockUI.stop();
  }

  private setNoteUnderEdit(note?: Note) {
    if (note) {
      this.noteUnderEdit.set({ text: note.text, note });
    } else {
      this.noteUnderEdit.set({ text: "" });
    }
  }

  private updateNoteInUi(note: Note) {
    const oldNote = this.notes().find(x => x.noteID === note.noteID);

    if (oldNote) {
      this.notes.update(notes => [...notes.filter(x => x.noteID !== note.noteID), note]);
    } else {
      this.notes.update(notes => [...notes, note]);
    }
  }

  private async promptToSave() {
    const isNewNote = !this.noteUnderEdit().note
    const isDirty = (isNewNote && this.noteUnderEdit().text !== "") || (!isNewNote && this.noteUnderEdit().text !== this.noteUnderEdit().note?.text)

    if (!isDirty) {
      return;
    }

    const saveConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: "Unsaved changes",
        message: "There are unsaved changes to a note. Would you like to save the changes?",
        mainButtonText: "Yes",
        altButtonText: "No",
        showAltButton: true,
        showIcon: true
      },
    });
    await firstValueFrom(saveConfirmationDialog.afterClosed()
      .pipe(
        map(async (hasUserChosenToSave) => {

          if (!hasUserChosenToSave) {
            return;
          }

          if (isNewNote) {
            await this.createNote();
          } else {
            await this.updateNote();
          }
        })));
  }

  private showErrorSnackbar(errorMessage: string) {
    this.snackBar.open(errorMessage, "Close", { duration: this.snackbarDurationMs })
  }

  private async blockUiWrapper(func: () => Promise<void>) {
    this.showLoadingIndicator();
    try {
      await func();
    } finally {
      this.hideLoadingIndicator();
    }
  }
}

interface NoteUnderEdit {
  text: string;
  note?: Note;
}

export interface DialogData {
  pageId: number;
}